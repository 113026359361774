<template>
    <v-select :multiple="multiple" :filterable="true" :options="options" v-model="selected" :placeholder="placeholder" />
</template>
<script>

import _ from "lodash";
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        draw: {
            type: Number,
        },
        placeholder: {
            type: String
        }
    },
    data() {
        return {
            baseUrl: "/api/wms/v1/outbound/simple-sto/warehouse",
            options: [],
            selected: (this.multiple) ? [] : null
        };
    },
    methods: {
        get() {
            // this.$vs.loading();
            this.$http
                .get(this.baseUrl, {
                    params: {
                        order: "name",
                        sort: "asc"
                    }
                })
                .then(resp => {
                    if (resp.status == "success") {
                        resp.data.records.map(function (x) {
                            return (x.label = x.code + " " + x.name);
                        });

                        this.options = resp.data.records;
                        // this.$vs.loading.close();
                    } else {
                        this.options = []
                        // this.$vs.loading.close();
                        console.log(resp.data);
                    }
                });
        }
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        }
    },
    mounted() {
        this.get();
    }
};
</script>